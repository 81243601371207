<template>
  <div>
    <div class="header-container">
      <v-breadcrumbs>
        <v-breadcrumbs-item class="breadcrumbs-hover-default">
          <i class="far fa-list-alt" style="margin: 0 10px 0 0px"></i>
          <span>{{ $t("todolist.todoList") }}</span>
        </v-breadcrumbs-item>
      </v-breadcrumbs>
    </div>
    <div class="page-content">
      <div class="content">
        <!-- <h2 class="mb-2">
        <span><i class="far fa-list-alt"></i></span> {{ $t("todolist.todoList") }}
      </h2> -->
        <v-sheet width="100%">
          <v-row dense :style="{ padding: '1rem' }">
            <v-col cols="12" md="3" lg="2">
              <label class="label-input">{{
                $t("todolist.search_title")
              }}</label>
              <v-text-field class="btn btn-color btn-border btn-border-color" outlined dense v-model="searchItem"
                prepend-inner-icon="mdi-magnify" :placeholder="$t('todolist.search_placeholder')"
                @keypress.enter="fetchTodoList">
              </v-text-field>
            </v-col>
            <v-col cols="12" md="3" lg="2">
              <label class="label-input"
                style="  white-space: nowrap;width: 5px;overflow: hidden;text-overflow: ellipsis; ">{{
                  $t("todolist.dept") }}</label>
              <v-autocomplete filled dense item-text="name" item-value="id" outlined :items="listDepartment"
                hide-selected @change="fetchTodoList" v-model="department_id">
                <template v-slot:item="data">
                  <template>
                    <v-list-item-content>
                      <v-list-item-title v-html="data.item.name"></v-list-item-title>
                    </v-list-item-content>
                  </template>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col cols="12" md="3" lg="2">
              <label class="label-input">{{ $t("todolist.branch") }}</label>
              <v-autocomplete filled dense item-text="branch_name" item-value="id" outlined :items="listBranch"
                hide-selected v-model="branch_id" @change="fetchTodoList">
                <template v-slot:item="data">
                  <template>
                    <v-list-item-content>
                      <v-list-item-title v-html="data.item.branch_name"></v-list-item-title>
                    </v-list-item-content>
                  </template>
                </template>
              </v-autocomplete>
            </v-col>

            <v-col cols="12" md="3" lg="2">
              <label class="label-input">{{ $t("todolist.startDate") }}</label>
              <v-menu v-model="startDate" :close-on-content-click="false" :nudge-right="40"
                transition="scale-transition" offset-y min-width="auto">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field v-model="start_date" readonly outlined dense v-bind="attrs" v-on="on"></v-text-field>
                </template>
                <v-date-picker v-model="start_date" @input="startDate = false"></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" md="3" lg="2">
              <label class="label-input">{{ $t("todolist.endDate") }}</label>
              <v-menu v-model="endDate" :close-on-content-click="false" :nudge-right="40" transition="scale-transition"
                offset-y min-width="auto">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field v-model="end_date" readonly outlined dense v-bind="attrs" v-on="on"></v-text-field>
                </template>
                <v-date-picker v-model="end_date" @input="endDate = false"></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" md="2" lg="2">
              <label class="label-input">{{ $t("todolist.status") }}</label>
              <v-select outlined dense :items="todoStatus" item-value="name" item-text="name" v-model="status">
              </v-select>
            </v-col>
          </v-row>
          <v-row dense :style="{ padding: '0 1rem 0', marginTop: '-40px', marginBottom: '20px' }">
            <v-col>
              <v-btn class="btn-action" style="margin-left: 0;" @click="fetchTodoList">
                {{ $t("todolist.search") }}
              </v-btn>
              <v-btn class="btn-action confirm" @click="exportExcel" style="margin-left: 0;" v-if="todoList.length > 0">
                {{ $t("todolist.export") }}
              </v-btn>
            </v-col>
          </v-row>
        </v-sheet>
        <v-sheet class="mt-4 py-4 table-todolist" width="auto" height="auto" color="#fff" style="margin-left: 1.5em ;margin-right: 1.5em ;">
        <!-- <v-sheet class="mt-4 pa-4 table-todolist" width="100%" height="auto" color="white"> -->
          <table class="table table-view">
            <thead>
              <tr>
                <th class="text-left" :style="{ minWidth: '7rem !important', border: '1px solid gray' }">
                  {{ $t("todolist.todoNumber") }}
                </th>
                <th class="text-left" :style="{ minWidth: '15rem !important', border: '1px solid gray' }">
                  {{ $t("todolist.name") }}
                </th>
                <th class="text-left" :style="{ minWidth: '18rem !important', border: '1px solid gray' }">
                  {{ $t("todolist.description") }}
                </th>
                <th class="text-left" :style="{ minWidth: '10rem !important', border: '1px solid gray' }">
                  {{ $t("todolist.remark") }}
                </th>
                <th class="text-left" :style="{ minWidth: '9rem !important', border: '1px solid gray' }">
                  {{ $t("todolist.assignor") }}
                </th>
                <th class="text-left" :style="{ minWidth: '12rem !important', border: '1px solid gray' }">
                  {{ $t("todolist.branch_name") }}
                </th>
                <th class="text-left" :style="{ minWidth: '12rem !important', border: '1px solid gray' }">
                  {{ $t("todolist.dept_name") }}
                </th>
                <th class="text-left" :style="{ minWidth: '9rem !important', border: '1px solid gray' }">
                  {{ $t("todolist.assignee") }}
                </th>
                <th class="text-left" :style="{ minWidth: '12rem !important', border: '1px solid gray' }">
                  {{ $t("todolist.branch_name") }}
                </th>
                <th class="text-left" :style="{ minWidth: '12rem !important', border: '1px solid gray' }">
                  {{ $t("todolist.dept_name") }}
                </th>
                <th class="text-left" :style="{ minWidth: '7rem !important', border: '1px solid gray' }">
                  {{ $t("todolist.startDate") }}
                </th>
                <th class="text-left" :style="{ minWidth: '7rem !important', border: '1px solid gray' }">
                  {{ $t("todolist.deadline") }}
                </th>
                <th class="text-left" :style="{ minWidth: '7rem !important', border: '1px solid gray' }">
                  {{ $t("todolist.completionDate") }}
                </th>
                <th class="text-left" :style="{ minWidth: '6rem !important', border: '1px solid gray' }">
                  {{ $t("todolist.status") }}
                </th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, idx) in todoList" :key="idx" @click="selectTr(idx)"
                :style="{ backgroundColor: selectTrIndex === idx ? '#e3fafc' : 'transparent' }">
                <td class="text-start">{{ item.todo_number }}</td>
                <td>
                  {{ item.title }}
                </td>
                <td>
                  {{ item.description }}
                </td>
                <td>
                  <div>
                    {{ item.remark ? item.remark : "-" }}
                  </div>
                </td>
                <td>{{ item.assignor.name }} {{ item.assignor.surname }}</td>
                <td>{{ item.assignor.branch_name }}</td>
                <td>{{ item.assignor.department_name }}</td>
                <td>
                  {{ item.assignee.name }}
                </td>
                <td>
                  {{ item.assignee.branch_name }}
                </td>
                <td>
                  {{ item.assignee.department_name }}
                </td>
                <td style="white-space: nowrap">
                  {{ item.start_date }}
                </td>
                <td style="white-space: nowrap">
                  {{ item.deadline }}
                </td>
                <td class="text-center">
                  <div>
                    {{ item.completion_date ? item.completion_date : "-" }}
                  </div>
                </td>
                <td>
                  <div v-if="item.status === 'pending'">
                    <span class="status pending">pending</span>
                  </div>
                  <div v-if="item.status === 'approved'">
                    <span class="status approve">approve</span>
                  </div>
                  <div v-if="item.status === 'success'">
                    <span class="status success">success</span>
                  </div>
                  <div v-if="item.status === 'confirm'">
                    <span class="status confirm">confirm</span>
                  </div>
                  <div v-if="item.status === 'updated'">
                    <span class="status updated">updated</span>
                  </div>
                  <div v-if="item.status === 'rejected'">
                    <span class="status rejected">rejected</span>
                  </div>
                </td>
                <td></td>
              </tr>
            </tbody>
          </table>
          <loading v-if="isLoading" />
          <Pagination v-if="pagination.total_pages > 1" :pagination="pagination" :offset="offset"
            @paginate="fetchTodoList">
          </Pagination>
        </v-sheet>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import Pagination from "../../components/Paginate/Pagination";
import Loading from "@/components/Loading";

export default {
  name: "TodoList",
  components: {
    Pagination,
    Loading,
  },
  data() {
    return {
      isLoading: false,
      offset: 10,
      pagination: {},
      per_page: 10,
      status: "all",
      selectTrIndex: '',
      todoStatus: [
        {
          id: 0,
          name: "all",
        },
        {
          id: 1,
          name: "pending",
        },
        {
          id: 2,
          name: "confirm",
        },
        {
          id: 3,
          name: "updated",
        },
        {
          id: 4,
          name: "success",
        },
        {
          id: 5,
          name: "rejected",
        },
      ],
      startDate: false,
      start_date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      endDate: false,
      end_date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      todoList: [],
      filter: "",
      department_id: '',
      listDepartment: [],
      listBranch: [],
      branch_id: '',
      searchItem: "",
    };
  },
  methods: {
    fetchDepartment() {
      this.$axios.get(`company/list/departments/selected`, {}).then((res) => {
        if (res.status === 200) {
          this.listDepartment = res.data.data;
        }
      });
    }, fetchBranch() {
      this.$axios
        .get(`company/list/company/branches`)
        .then((res) => {
          if (res) {
            this.listBranch = res.data.companyBranches;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    fetchTodoList() {
      const item = {
        start_date: moment(this.start_date).format("YYYY-MM-DD"),
        end_date: moment(this.end_date).format("YYYY-MM-DD"),
        status: this.status,
        per_page: this.per_page,
        page: this.pagination.current_page,
      }

      if (this.department_id) {
        item.department_id = this.department_id
      }

      if (this.branch_id) {
        item.branch_id = this.branch_id
      }

      if (this.searchItem) {
        item.searchItem = this.searchItem
      }

      this.isLoading = true;
      this.$axios
        .post(`company/report-todo-lists`, item)
        .then((res) => {
          this.isLoading = false;
          if (res.status === 200) {
            this.todoList = res.data.data.data;
            this.pagination = res.data.data.pagination;

            if (!this.todoList.length > 0) {
              this.pagination.current_page = this.pagination.current_page - 1;
            }
          }
        });
    },
    selectTr(idx) {
      this.selectTrIndex = idx;
    },
    exportExcel() {
      const item = {
        star_date: moment(this.start_date).format("YYYY-MM-DD"),
        end_date: moment(this.end_date).format("YYYY-MM-DD"),
        status: this.status,
      };
      this.isLoading = true;
      this.$axios
        .post(`company/export-todo-lists-to-excel`, item, {
          responseType: "blob",
        })
        .then((res) => {
          const fileUrl = window.URL.createObjectURL(new Blob([res.data]));
          const fileLink = document.createElement("a");
          fileLink.href = fileUrl;
          fileLink.setAttribute("download", "todolist.xlsx");
          document.body.appendChild(fileLink);
          fileLink.click();
          document.body.removeChild(fileLink);
          this.isLoading = false;
        });
    },
  },
  created() {
    this.fetchTodoList();
    this.fetchDepartment();
    this.fetchBranch();
  },
};
</script>

<style scoped lang="scss">
.main-report {
  width: 100%;
  height: auto;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: rgba(223, 230, 233, 0.3);
  padding: 8px;
  font-family: $font-family;

  h2 {
    font-size: 20px;
  }

  .card-select-date {
    display: flex;
    align-items: center;
    padding: 8px;
  }
}

.status {
  font-size: 12px;
  letter-spacing: 1px;
  padding: 2px 8px;
  width: 60px;
  height: 20px;
  text-transform: capitalize;
  font-family: $font-family;
  border-radius: 60px;
}

.pending {
  background-color: rgba(128, 142, 155, 1);
  color: #ffffff;
}

.confirm {
  background-color: rgba(46, 204, 113, 1) !important;
  color: #ffffff;
}

.updated {
  background-color: rgba(255, 221, 89, 1) !important;
  color: #ffffff;
}

.rejected {
  background-color: rgba(192, 57, 43, 1) !important;
  color: #ffffff;
}

.approve {
  background-color: rgba(38, 222, 129, 1);
  color: #ffffff;
}

.success {
  background-color: rgba(30, 144, 255, 1) !important;
  color: #ffffff;
}

.table-todolist {
  width: 100%;
  height: auto;
  overflow-x: scroll;
}

table tbody tr:hover {
  background-color: #f0f9fa !important;
  cursor: pointer;
}

//start freezed
table th {
  position: sticky;
  top: 0;
  min-width: 120px !important;
  background-color: #f6f6f6;
  z-index: 1;
}

table th:nth-child(1),
table td:nth-child(1) {
  position: sticky;
  left: 0px;
  min-width: 8rem;
  z-index: 2 !important;
}

table th:nth-child(1) {
  background: #e9e9e9;
}

table td:nth-child(1) {
  background: #f4f4f4;
}

table td:nth-child(1),
table th:nth-child(1) {
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.75) !important;
  clip-path: inset(0px -15px 0px 0px) !important;
}

table td:nth-child(1),
table th:nth-child(1) {
  border-right: 1px solid rgb(226, 226, 226) !important;
}

//end freezed</style>
